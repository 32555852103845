<template>
    <div class="echart" id="echart-periodPie" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
    import echarts from 'echarts';
    import {getFourDwonThreeList,getFourDwonFourList} from "./overallSituation";

    export default {
        name: 'periodPie',
        data() {
            return {
                data: [],
                yearList:[],
                jyList:[],
                dyList:[],
                jjyList:[],
            }
        },
        methods: {
            getFun(){
                getFourDwonThreeList().then(res=>{
                    let list = res;
                    let _ = this;
                    this.jjyList = [];
                    this.yearList = [];
                    this.dyList = [];
                    this.jyList = [];
                    list.forEach(function(value) {
                        _.yearList.push(value.year);
                        _.jyList.push(value.jy);
                        _.dyList.push(value.dy);
                        _.jjyList.push(value.jjy);
                    });
                    getFourDwonFourList().then(res1=>{
                        this.data = res1;
                        this.initChart();
                    })


                })
            },
            initChart() {
                let getchart = echarts.init(document.getElementById('echart-periodPie'));
                var periodPieOption = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    title: {
                        text: '占比情况',
                        textStyle: {
                            color: '#f2f2f2',
                            fontSize: 11,
                        },
                        subtextStyle: {
                            fontSize: 30,
                            color: ['#ff9d19']
                        },
                        x: '80%',
                        y: '45%',
                    },
                    legend: {
                      top: -40,
                       left: 'center',
                        data: ['就业', '待业', '毕业'],
                        orient: 'horizontal',
                        itemGap: 25,
                        padding: [50, 15, 0, 0],
                        textStyle: {
                            fontSize: 12,
                            color: '#fff'
                        },
                        right: '4%',
                    },
                    grid: {
                        top: '20%',
                        left: '4%',
                        right: '30%',
                        bottom: '12%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data:this.yearList,
                        axisLabel: {
                            textStyle: {
                                color: '#7B90CC'
                            },
                            formatter: function (params) {
                                return params.split(' ')[0]
                            }
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                    },
                    yAxis: {
                        name: '',
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#7B90CC'
                            }
                        },

                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: 'rgb(23,255,243,0.3)'
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: 'rgba(255,0,0,0.0)'
                            }
                        }
                    },
                    series: [{
                        name: '就业',
                        type: 'line',
                        color: 'rgba(23, 255, 243)',
                        smooth: true, //是否平滑曲线显示
                        showSymbol: false,
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(23, 255, 243' + ', 1)'
                                }, {
                                    offset: 0.8,
                                    color: 'rgba(23, 255, 243' + ', 0.02)'
                                }], false),
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        symbol: 'circle',
                        symbolSize: 5,
                        data: this.jyList
                    },
                        {
                            name: '待业',
                            type: 'line',
                            color: 'rgba(119,61,190)',
                            smooth: true,
                            showSymbol: false,
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: 'rgba(119,61,190' + ', 1)'
                                    }, {
                                        offset: 0.8,
                                        color: 'rgba(119,61,190' + ', 0.02)'
                                    }], false),
                                    shadowColor: 'rgba(0, 0, 0, 0.1)',
                                    shadowBlur: 10
                                }
                            },
                            symbol: 'circle',
                            symbolSize: 5,
                            data: this.dyList
                        }, {
                            name: '毕业',
                            type: 'line',
                            color: 'rgba(65,133,240)',
                            smooth: true,
                            showSymbol: false,
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: 'rgba(65,133,240' + ', 1)'
                                    }, {
                                        offset: 0.8,
                                        color: 'rgba(65,133,240' + ', 0.02)'
                                    }], false),
                                    shadowColor: 'rgba(0, 0, 0, 0.1)',
                                    shadowBlur: 10
                                }
                            },
                            symbol: 'circle',
                            symbolSize: 5,
                            data:  this.jjyList
                        },
                        {
                            color: ['rgba(23, 255, 243', 'rgba(119,61,190', 'rgba(65,133,240'],
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} ({d}%)'
                            },
                          radius: ['60%', '75%'],
                          center: ['85%', '50%'],
                            type: 'pie',
                            label: {
                                position: 'inner',
                                formatter: '{b}：{c}亿元',
                                show: false
                            },
                            labelLine: {
                                normal: {
                                    show: false,
                                    length: 30,
                                    length2: 55
                                },
                                emphasis: {
                                    show: false
                                }
                            },
                            data: this.data,

                        },]
                };

                getchart.setOption(periodPieOption);
                //随着屏幕大小调节图表
                window.addEventListener('resize', () => {
                    getchart.resize();
                });
            },
        },
        mounted() {
            this.getFun();
        },
    };
</script>

<style scoped></style>
