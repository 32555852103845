import { render, staticRenderFns } from "./currentPeriodGauge.vue?vue&type=template&id=1ecfa462&scoped=true&"
import script from "./currentPeriodGauge.vue?vue&type=script&lang=js&"
export * from "./currentPeriodGauge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ecfa462",
  null
  
)

export default component.exports