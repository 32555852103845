<template>
  <div class="head">
    <!--  标题  -->
    <div class="bt">
      <p class="bt1">总体情况统计分析</p>
    </div>
    <div class="middle">
      <!--  左边    -->
      <div style="width: calc((100% - 20px) * 0.19) ">
        <div class="title">
          数据统计
        </div>
        <div style="display: flex;flex-direction: column;height: calc(100% - 40px);" class="m-left">
          <div class="admissionsPeople1">
            <img :src="org" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">{{data[0].name}}</p>
              <div class="sjtjclass">
                <p class="NumClass">{{data[0].rzjgNum}}</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">{{data[0].zrjgBfb + '%'}}</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>

          </div>
          <div class="admissionsPeople1">
            <img :src="teacher" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">{{data[1].name}}</p>
              <div class="sjtjclass">

                <p class="NumClass">{{data[1].rzjgNum}}</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">{{data[1].zrjgBfb + '%'}}</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>

          </div>
          <div class="admissionsPeople1">
            <img :src="student" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">{{data[2].name}}</p>
              <div class="sjtjclass">
                <p class="NumClass">{{data[2].rzjgNum}}</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">{{data[2].zrjgBfb + '%'}}</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>

          </div>
          <div class="admissionsPeople1">
            <img :src="getJob" width="35" height="35" style="margin-right: 5px">
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">{{data[3].name}}</p>
              <div class="sjtjclass">
                <p class="NumClass">{{data[3].rzjgNum}}</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">{{data[3].zrjgBfb + '%'}}</span>
                </p>
                <img :src="zhu" width="60" height="25">
              </div>
            </div>


          </div>
          <div class="admissionsPeople1">
            <img :src="studyTime" width="35" height="35" style="margin-right: 5px">
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">{{data[4].name}}</p>
              <div class="sjtjclass">
                <p class="NumClass">{{data[4].rzjgNum}}</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">{{data[4].zrjgBfb + '%'}}</span>
                </p>
                <img :src="zhu" width="60" height="25">
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--中间-->
      <div style="width: calc((100% - 20px) * 0.62);margin-left: 10px ">
        <div style="" class="org">
          <div class="hint">
            <img :src="hint" width="100%" height="100%">
            <div class="hint1">
              机构总数:{{dataCentre.topVO.jg}}
            </div>
          </div>

          <div class="NumberOfTeachers">
            <img :src="hint" width="100%" height="100%">
            <div class="NumberOfTeachers1">
              教师总数:{{dataCentre.topVO.js}}
            </div>
          </div>

          <div class="studnts">
            <img :src="hint" width="100%" height="100%">
            <div class="studnt1">
              学员总数:{{dataCentre.topVO.xy}}
            </div>
          </div>

          <div class="studnts2">
            <img :src="hint" width="100%" height="100%">
            <div class="studnt2">
              就业学员总数:{{dataCentre.topVO.jy}}
            </div>
          </div>
          <div class="desc-wrap" style="">
            <!--     招生人次环比       -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:16px">{{dataCentre.orderZsVO.number}}</span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{dataCentre.orderZsVO.hb}}%</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">招生人次环比</p>
            </div>

            <!--   开班数量环比  -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:16px">{{dataCentre.orderKbVO.number}} </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{dataCentre.orderKbVO.hb}}%</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">开班数量环比</p>
            </div>

            <!--      报考人次环比      -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:16px">{{dataCentre.orderBkVO.number}} </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{dataCentre.orderBkVO.hb}}%</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">报考人次环比</p>
            </div>
            <!--      人均学时环比      -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:16px">{{dataCentre.orderXsVO.number}} </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">{{dataCentre.orderXsVO.hb}}%</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">人均学时环比</p>
            </div>
          </div>

        </div>
      </div>
      <!--      右边-->
      <div style="width: calc((100% - 20px) * 0.19);margin-left: 10px;display: flex;flex-direction: column ">
        <!--        上-->
        <div style="height: calc((100% - 10px) * 0.5);position: relative">
          <div class="title">就业情况</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="marquee-wrap">
            <div class="marquee-list" :class="{'animate-up': animateUp}">
              <div class="list-child"
                   v-for="(item, index) in yuanData" :key="index">
                <div>{{item.name}}</div>
                <div>{{item.time}}</div>
              </div>
            </div>

          </div>
        </div>
        <!--        下-->
        <div style="height: calc((100% - 10px) * 0.5);margin-top: 10px;position: relative">
          <div class="title">
            培训就业率统计
          </div>
          <border style="width: 100%;height:  calc(100% - 40px)"/>
          <div class="degreeDistribution1">
            <!--   本期就业率   -->
            <current-period-gauge style="height: 80%"/>
            <!--   本年就业率   -->
            <current-year-gauge style="height: 80%"/>

            <!--   去年就业率   -->
            <last-year-gauge style="height: 80%"/>
          </div>
        </div>
      </div>
    </div>
    <!--    底部-->
    <div class="bottom">

      <div style="width: calc((100% - 20px) * 0.25) ;position: relative">
        <div class="title">
          学时趋势
        </div>
        <border style="width: 100%;height: calc(100% - 40px);"/>
        <div class="echart-wrap">
          <school-trends-line/>
        </div>
      </div>
      <div style="width: calc((100% - 20px) * 0.25);margin-left: 10px;position: relative ">
        <div class="title">
          培训合格率统计
        </div>
        <border style="width: 100%;height: calc(100% - 40px);"/>
        <div class="echart-wrap">
        <student-pass-rate-bar />
        </div>
      </div>
      <div style="width: calc((100% - 20px) * 0.5);margin-left: 10px ;position: relative">
        <div class="title">
          招生、报考、毕业趋势
        </div>
        <border style="width: 100%;height: calc(100% - 40px);"/>
        <div class="echart-wrap">
        <periodPie />
        </div>
      </div>
    </div>
  </div>
</template>
<!--总体情况分析-->
<script>
  import bt from '@/static/img/标题.png'
  import org from '@/static/img/机构1.png'
  import teacher from '@/static/img/教师1.png'
  import student from '@/static/img/学员1.png'
  import getJob from '@/static/img/就业1.png'
  import studyTime from '@/static/img/学习时长1.png'
  import borderImg from '@/static/img/边框2.png'
  import borderImg_2 from '@/static/img/边框-2.png'
  import zhu from '@/static/img/柱1.png'
  import earth from '@/static/img/earth.png'
  import SchoolTrendsLine from "@/view/overallSituation/SchoolTrendsLine";
  import periodPie from './periodPie'
  import currentPeriodGauge from './currentPeriodGauge'
  import currentYearGauge from './currentYearGauge'
  import lastYearGauge from './lastYearGauge'
  import StudentPassRateBar from './StudentPassRateBar'
  import hint from '@/static/img/hint.png'
  // import echartMap from './echartMap'
  import border from "../../components/border.vue"
  import {getFourLeftTopList,getFourJyList,getThreeOnList,getFourOrderList} from './overallSituation'
  export default {
    name: "overallSituation",
    components: {
      border,
      SchoolTrendsLine,
      periodPie,
      currentPeriodGauge,
      lastYearGauge,
      currentYearGauge,
      StudentPassRateBar,
      // echartMap,
    },
    data() {
      return {
        bt: bt, //标题图片
        org: org, //机构图片
        zhu: zhu,
        teacher: teacher,
        student: student,
        getJob: getJob,
        studyTime: studyTime,
        borderImg: borderImg,
        borderImg_2: borderImg_2,
        earth: earth,
        value: 0,
        bodyHight: 0,
        pwidth: 0,//公告文本的宽度
        windowWidth: document.documentElement.clientWidth,// 设备屏幕的宽度
        data: {},
        dataCentre: {},
        yuanData: [],
        teachets: '',
        students: '',
        jystudents: '',
        orgNum: '',
        zsbfb: '',
        zsNum: '',
        bkNum: '',
        bkbfb: '',
        kbNum: '',
        kbbfb: '',
        xsNum: '',
        xsbfb: '',
        animateUp: false,
        timer: null,
        hint: hint, //提示图片
      }
    },
    methods: {
      getFun(){
        getFourOrderList().then(res=>{
          this.dataCentre = res;
        })
        getFourLeftTopList().then(res=>{
          this.data = res;
        })

        getFourJyList().then(res=>{
          this.yuanData = res;
        })
        // getThreeOnList().then(res=>{
        //   let list = res;
        //   let _ = this;
        //   list.forEach(function(value) {
        //     if(value.name == '教师总数'){
        //       _.teachets = value.value
        //     }
        //     if(value.name == '就业学员总数'){
        //       _.jystudents = value.value
        //     }
        //     if(value.name == '学员总数'){
        //       _.students = value.value
        //     }
        //     if(value.name == '机构总数'){
        //       _.orgNum = value.value
        //     }
        //     if(value.name == '招生人次环比'){
        //       _.zsNum = value.value
        //       _.zsbfb = value.hb
        //     }if(value.name == '报考人次环比'){
        //       _.bkNum = value.value
        //       _.bkbfb = value.hb
        //     }if(value.name == '开班数量环比'){
        //       _.kbNum = value.value
        //       _.kbbfb = value.hb
        //     }if(value.name == '人均学时环比'){
        //       _.xsNum = value.value
        //       _.xsbfb = value.hb
        //     }
        //   });
        // })
      },
      scrollAnimate() {
        this.animateUp = true
        setTimeout(() => {
          this.yuanData.push(this.yuanData[0])
          this.yuanData.shift()
          this.animateUp = false
        }, 500)
      }
    },
    mounted() {
      this.getFun();
      this.timer = setInterval(this.scrollAnimate, 1500);
    },
    destroyed() {
      clearInterval(this.timer)
    }
  }
</script>

<style scoped lang="scss">

  .head {
    height: 100%;
    overflow-y: hidden;
    background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
  }

  .bt {
    background-image: url("../../static/imgs/标题.png");
    background-size: 100% 45px;
    background-repeat: no-repeat;
    background-position: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
  }

  .bt1 {
    color: rgb(0, 255, 255);
    font-size: 27px;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  .middle {
    display: flex;
    padding: 0 10px;
    height: calc((100% - 75px) * 0.62);
  }

  .bottom {
    display: flex;
    padding: 0 10px;
    margin-top: 10px;
    height: calc((100% - 105px) * 0.38);
  }

  .title {
    color: white;
    background-color: rgb(0, 26, 77);
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  /*机构分布、综合排名*/
  .admissionsPeople {
    width: 100%;
    height: 40px;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .m-left .admissionsPeople1:last-child {
    margin-bottom: 0;
  }

  .admissionsPeople1 {
    width: 100%;
    height: calc((100% - 40px) * 0.20);
    background-color: rgb(0, 20, 61);
    margin-bottom: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;


    .sjtjclass {
      color: white;
      width: 100%;
      height: 90%;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;


      .NumClass {
        color: #2db7f5;
        font-size: 18px;
      }

      .sjhbClass {
        font-size: 12px;
      }

    }

  }

  .SchoolTrends2 {
    width: 92%;
    height: 88%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 2%;
    top: 20%;
  }

  /*内容*/
  .nrClass {
    width: 100%;
    height: 58%;
  }

  .dbClass {
    width: 100%;
    height: 30%;
    margin-top: 15px;
  }

  .rowLeft {
    height: 88%;
  }

  /*学员合格率*/
  .StudentPassRate {
    width: 100%;
    height: 40px;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
    margin-top: 5px;
  }

  /*学员合格率*/
  .StudentPassRate1 {
    width: 100%;
    height: 200px;
    margin-top: 15px;
    position: relative; /*相対定位*/
  }

  .StudentPassRate2 {
    width: 100%;
    height: 100%;
    position: absolute; /*絶対定位*/
    bottom: 0;
    left: 0;
  }

  .echart-wrap {
    position: absolute;
    top: 60px;
    z-index: 999;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center
  }

  /*教师学员年龄分布*/
  .ageDistribution {
    width: 100%;
    height: 40px;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .ageDistribution1 {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /*position:relative;!*相対定位*!*/
    overflow: hidden;
  }

  /*教师学员学历分布*/
  .degreeDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
    margin-top: 20px;
  }

  .degreeDistribution1 {
    width: 100%;
    position: absolute; /*相対定位*/
    height: calc(100% - 40px);
    top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 999;
  }

  /*机构*/
  .org {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 20, 61);

  }

  .tendency2 {
    width: 94%;
    height: 90%;
    position: absolute; /*絶対定位*/
    bottom: 0;
    left: 0;
  }


  .pxtjClass {
    width: 100%;
    height: 39%
  }

  .marquee-wrap {
    width: 100%;
    height: calc(100% - 70px);
    top: 50px;
    z-index: 999;
    margin: 15px auto 0 auto;
    overflow: hidden;
    position: absolute;

    .marquee-list {
      .list-child {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;
        font-size: 14px;
        color: white;
        position: relative;
        padding-left: 30px;
        padding-right: 15px;

        &::before {
          content: " ";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          left: 15px;
          height: 12px;
          border-radius: 12px;
          background: #ffffff;
        }
      }

      li {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        list-style: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }

    }

    .animate-up {
      transition: all 0.5s ease-in-out;
      transform: translateY(-40px);
    }
    .desc {
      background-image: url("../../static/img/content1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 10px;
    }

  }
  /*机构*/
  .org {
    width: 100%;
    background-color: rgb(0, 18, 53);
    height: calc((100% - 10px) * 0.62);
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20%;
      right: 0;
      margin: auto;
      background-image: url("../../static/img/机构.png");
      background-size: 60% auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .hint {
      width: 130px;
      height: 50px;
      position: relative;
      top: 11%;
      left: 62%;
    }

    .hint1 {
      width: 130px;
      height: 50px;
      position: absolute;
      color: rgb(0, 255, 255);
      top: 20%;
      left: 25%;
    }

    .desc-wrap {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      padding: 0 10px;
      height: 20%;
      display: flex;
      justify-content: space-around;

    }

    .desc {
      background-image: url("../../static/img/content1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 10px;
    }

  }


  /*招生人次环比*/
  .AttendanceMonth {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 6%;

  }

  /*开班数量环比*/
  .classesSequential {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 29%;

  }

  /*报考人次环比*/
  .MonthlyApplicants {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 53%;

  }

  /*人均学时环比*/
  .PerCapitaClassHours {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 77%;

  }

  /*机构*/
  .org {
    width: 100%;
    background-color: rgb(0, 18, 53);
    height: calc((100% - 10px));
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20%;
      right: 0;
      margin: auto;
      background-image: url("../../static/img/机构.png");
      background-size: 60% auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .hint {
      width: 130px;
      height: 50px;
      position: relative;
      top: 11%;
      left: 62%;
    }

    .hint1 {
      width: 130px;
      height: 50px;
      position: absolute;
      color: rgb(0, 255, 255);
      top: 20%;
      left: 25%;
    }

    .desc-wrap {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      padding: 0 10px;
      height: 20%;
      display: flex;
      justify-content: space-around;

    }

    .desc {
      background-image: url("../../static/img/content1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 10px;
    }

  }


  /*招生人次环比*/
  .AttendanceMonth {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 6%;

  }

  /*开班数量环比*/
  .classesSequential {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 29%;

  }

  /*报考人次环比*/
  .MonthlyApplicants {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 53%;

  }

  /*人均学时环比*/
  .PerCapitaClassHours {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 77%;

  }

  /*教师数量*/
  .NumberOfTeachers {
    width: 130px;
    height: 50px;
    position: relative;
    top: 10%;
    left: 15%;

  }

  .NumberOfTeachers1 {
    width: 130px;
    height: 50px;

    position: absolute;
    color: rgb(0, 255, 255);

    top: 20%;

    left: 18%;

  }

  .NumberOfTeachers img {

    transform: rotateY(180deg);

  }
  /*教师数量*/
  .studnts {
    width: 130px;
    height: 50px;
    position: relative;
    top: 27%;
    left: 70%;

  }

  .studnt1 {
    width: 130px;
    height: 50px;

    position: absolute;
    color: rgb(0, 255, 255);

    top: 25%;

    left: 20%;

  }
  .studnt2 {
    width: 130px;
    height: 50px;
    position: relative;
    top: -88%;
    left: 5%;

  }

  .studnts2 {
    width: 130px;
    height: 50px;

    position: absolute;
    color: rgb(0, 255, 255);

    top: 55%;

    left: 20%;

  }
  .studnts2 img {

    transform: rotateY(180deg);

  }

</style>
