<template>
  <div id="echart-line-currentPeriodGauge" :style="{ width: '100%', height: '100%'}" ></div>
</template>

<script>
import echarts from 'echarts';
import {getFourRightOnList} from "./overallSituation";
export default {
  name: 'currentPeriodGauge',
  data(){
    return {
      percent:'',
      text:'本期就业率',
    }
  },
  methods: {
    getFun(){
      getFourRightOnList().then(res=>{
        let list = res;
        let _ = this;
        list.forEach(function(value) {
          if(value.name =='本期就业率' ){
            _.percent = value.percent;
          }
        });
        this.initChart();

      })
    },
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-line-currentPeriodGauge'));
      var currentPeriodGaugeOption = {
        title: {
          show: true,
          text: this.text,
          x: '47%',
          y: '84%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 15,
          },

        },
        xAxis: {
          show: false, //是否展示x轴
          min: function(value) { //调整x轴上面数据的位置
            return value.min - 7;
          },
          max: function(value) {
            return value.max + 7;
          },
          splitLine: {
            lineStyle: {
              show: true,
              type: 'dashed'
            }
          },
          "axisLabel": {
            "interval": 0,
            rotate: 40,
            textStyle: {
              fontSize: 10,
              color: '#000'
            },
          },
          data: ['1', '2', '3', '4', '5']
        },
        yAxis: {
          show: false,
          name: '',
          max: 200,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            "name": '',
            "type": 'pie',
            "radius": ['50%', '70%'],
            "avoidLabelOverlap": false,
            "startAngle": 210,
            color: [{
              type: 'linear',
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0,
                color: 'rgba(73, 239, 105,0.5)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(73, 239, 105,0)' // 100% 处的颜色
              }]
            }, 'transparent'],
            "hoverAnimation": false, //是否开启 hover 在扇区上的放大动画效果。
            "legendHoverLink": false, //是否启用图例 hover 时的联动高亮。
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },
              "emphasis": {
                "show": true,
                "textStyle": {
                  "fontSize": '30',
                  "fontWeight": 'bold'
                }
              }
            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": 66,
              "name": '1',
              itemStyle: {
                borderWidth: 3,
                borderColor: 'rgba(123,123,123,.5)'
              }
            }, {
              "value": 34,
              "name": '2',
              itemStyle: {
                borderWidth: 0,
                borderColor: 'transparent'
              }
            }]
          },
          {
            "name": ' ',
            "type": 'pie',
            "radius": ['45%', '47%'],
            "avoidLabelOverlap": false, //是否启用防止标签重叠策略
            "startAngle": 0,
            "hoverAnimation": false,
            "legendHoverLink": false,
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },
              "emphasis": {
                "show": true,
                "textStyle": {
                  "fontSize": '30',
                  "fontWeight": 'bold'
                }
              }
            },
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(50, 139, 221,0.9)' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgba(50, 139, 221,0.2)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              }
            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": 50,
              "name": '1',
              itemStyle: {
                color: [{
                  type: 'linear',
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(50, 139, 221,0.9)' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgba(50, 139, 221,.2)' // 100% 处的颜色
                  }]
                }, 'transparent'],
              }
            },
              {
                "value": 50,
                "name": '2'
              }
            ]
          },
          {
            "name": '',
            "type": 'pie',
            "radius": ['50%', '70%'],
            "avoidLabelOverlap": false,
            "startAngle": -30,
            "color": ['rgba(34,34,34,.9)', "transparent", "transparent"],
            "hoverAnimation": false,
            "legendHoverLink": false,
            "clockwise": false, //饼图的扇区是否是顺时针排布。
            "itemStyle": {
              "normal": {
                "borderColor": "transparent",
                "borderWidth": "20"
              },
              "emphasis": {
                "borderColor": "transparent",
                "borderWidth": "20"
              }
            },
            "z": 10,
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },

            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": (100 - this.percent) *   240 / 360,

              "label": {
                normal: {
                  formatter: this.percent + '%',
                  position: 'center',
                  show: true,
                  textStyle: {
                    padding: [20, 0, 0, 0],
                    fontSize: '18',
                    fontWeight: 'normal',
                    color: 'rgba(0,230,179,1)'
                  }
                }
              },
              "name": ''
            }, {
              "value": 1,
              "name": ''
            }, {
              "value": 100 - (100 - this.percent) * 270 / 360,
              "name": ''
            }]
          },
          { // 底部
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],

            startAngle: 210,
            endAngle: -30,
            labelLine: {
              show: false
            },
            z: 15,
            silent: true,
            label: {
              show: true,
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 8,
                  padding: [-1, -35, -30, -40]
                },
                b: {
                  color: '#fff',
                  fontSize: 8,
                  padding: [0, 0, -30, -40]
                }
              },
              formatter: function(val) { // 底部0和100
                if (val.dataIndex === 0) {
                  return '{a|0}'
                }
                if (val.dataIndex === 2) {
                  return '{b|100}'
                }
              }
            },
            data: [{ // 底部两条线
              value: 3,
              itemStyle: {
                color: 'rgba(123,123,123,1)'
              }
            }, {
              value: 482,
              itemStyle: {
                color: 'transparent'
              }
            }, {
              value: 3,
              itemStyle: {
                color: 'rgba(123,123,123,1)'
              }
            }, {
              value: 245,
              itemStyle: {
                color: 'transparent'
              }
            }]
          },
          {
            "name": ' ',
            "type": 'pie',
            "radius": ['25%', '35%'],
            "avoidLabelOverlap": false, //是否启用防止标签重叠策略
            "startAngle": 150,
            "hoverAnimation": false,
            "legendHoverLink": false,
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },
              "emphasis": {
                "show": false,
                "textStyle": {
                  "fontSize": '30',
                  "fontWeight": 'bold'
                }
              }
            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": 34,
              "name": '1',
              itemStyle: {
                color: [{
                  type: 'linear',
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(50, 139, 221,0.4)' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgba(50, 139, 221,.2)' // 100% 处的颜色
                  }]
                }, 'transparent'],
              }
            },
              {
                "value": 66,
                "name": '2'
              }
            ]
          },
          {
            name: '指针',
            type: 'gauge',
            pointer: {
              show: false,
            },
            radius: "54%",
            startAngle:90, //刻度起始
            endAngle:90, //刻度结束
            z: 4,
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2, //刻度节点线长度
              lineStyle: {
                width: 3,
                color: 'rgba(255,255,255, 0.9)',
                shadowColor: 'rgba(255,255,255,.2)',
                shadowBlur:5
              } //刻度节点线
            },
            axisLabel: {
              color: 'rgba(255,255,255,0)',
              fontSize: 12,
            }, //刻度节点文字颜色
            axisLine: {
              lineStyle: {
                opacity: 0,
              }
            },
            detail:{
              show:false
            }
          },
          {
            name:'阴影',
            "type": 'pie',
            "radius": ['10%', '50%'],
            "avoidLabelOverlap": false,
            "startAngle": -22,
            // "color": ['transparent', "transparent", "transparent"],
            "hoverAnimation": false,
            "legendHoverLink": false,
            "clockwise": false, //饼图的扇区是否是顺时针排布。
            "itemStyle": {
              "normal": {
                "borderColor": "transparent",
                "borderWidth": "20"
              },
              "emphasis": {
                "borderColor": "transparent",
                "borderWidth": "20"
              }
            },
            "z": 10,
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },

            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": (100 - this.percent) * 240 / 360,
              "label": {
                normal: {
                  formatter: this.percent + '%',
                  position: 'center',
                  show: true,
                  textStyle: {
                    fontSize: '90',
                    fontWeight: 'normal',
                    color: 'transparent'
                  }
                }
              },
              itemStyle:{
                normal:{
                  color:'rgba(255,255,255,0)'
                }
              },
              "name": 'transparent'
            }, {
              "value": 10,
              "name": '',

            },
              {
                "value": 100 - (100 - this.percent) * 270 / 360,
                "name": '',
              }
            ]

          }

        ]};

      getchart.setOption(currentPeriodGaugeOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },
  },
  mounted() {
    // this.initChart();
    this.getFun();
  },
};
</script>

<style scoped></style>
