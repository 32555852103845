import server from '../../util/server';
import context from "../../util/context";

/**
 * 数据统计
 * @returns {*}
 */
export function getFourLeftTopList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourLeftTopList',
    });
}

/**
 * 就业情况
 * @returns {Promise | Promise<unknown>}
 */
export function getFourJyList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourJyList',
    });
}

/**
 * 就业情况
 * @returns {Promise | Promise<unknown>}
 */
export function getFourRightOnList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourRightOnList',
    });
}

/**
 * 第四屏下学时趋势
 * @returns {Promise | Promise<unknown>}
 */
export function getFourDwonOneList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourDwonOneList',
    });
}

/**
 * 培训合格率统计
 * @returns {Promise | Promise<unknown>}
 */
export function getFourDwonTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourDwonTwoList',
    });
}

/**
 * 招生、报考、结业趋势
 * @returns {Promise | Promise<unknown>}
 */
export function getFourDwonThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourDwonThreeList',
    });
}

/**
 * 招生、报考、结业趋势 饼图
 * @returns {Promise | Promise<unknown>}
 */
export function getFourDwonFourList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getFourDwonFourList',
    });
}

/**
 * 中间
 * @returns {Promise | Promise<unknown>}
 */
export function getThreeOnList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getThreeOnList',
    });
}
export function getFourOrderList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getFourOrderList',
    });
}
